import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const LIKERT_SCALE_RATINGS = [
  { value: 'Strongly Disagree', label: '' },
  { value: 'Disagree', label: '' },
  { value: 'Slightly Disagree', label: '' },
  { value: 'Neutral / Don\'t know', label: '' },
  { value: 'Slightly Agree', label: '' },
  { value: 'Agree', label: '' },
  { value: 'Strongly Agree', label: '' },
];

const LikertQuestion = React.forwardRef(({ question, onResponseChange, answered }, ref) => {
  const { text } = question;
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onResponseChange(`${text}: ${event.target.value}`);
  };

  return (
    <div ref={ref} style={{ padding: '12px 0px' }} className={answered ? 'answered' : ''}>
      <div className='question-text-slim'>{text}</div>

      <RadioGroup
        className='answers-slim'
        aria-label="likert-scale"
        name="likert-scale-radio-group"
        value={selectedValue}
        onChange={handleChange}
      >
        <div className='likert-answer-slim'>
          {LIKERT_SCALE_RATINGS.map((rating, index) => (
            <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <FormControlLabel
                value={rating.value}
                control={<Radio className={`radio-btn radio-btn-${index + 1}`} />}
                label=""
                style={{ margin: '0px' }}
              />
            </div>
          ))}
        </div>
        <div className="likert-label">
          <div className="likert-label-left">Disagree</div>
          <div className="likert-label-middle">Neutral / Don't Know</div>
          <div className="likert-label-right">Agree</div>
        </div>
      </RadioGroup>

    </div>
  );
});

export default LikertQuestion;
