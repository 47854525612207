import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function CategorySelection({ onCategorySelect, categories }) {
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    onCategorySelect(event.target.value);
  };

  return (
    <div className="category-selection-container">
      <div className="category-selection-title">
        What type of gear are you interested in?
      </div>
      <FormControl sx={{ marginTop: 2, width: 160 }} className="category-select-control">
        <Select
          value={selectedCategory}
          onChange={handleCategoryChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Select Category' }}
          className="category-select"
        >
          <MenuItem value="" disabled>
            {/* Placeholder text */}
          </MenuItem>
          {categories.map((category) => (
            <MenuItem key={category.name} value={category.name} sx={{fontSize: 17}}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
