import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function BudgetSelection({ onBudgetSelect }) {
  const [selectedBudget, setSelectedBudget] = useState('');

  const handleBudgetChange = (event) => {
    const budget = event.target.value;
    setSelectedBudget(budget);
    onBudgetSelect(budget);
  };

  return (
    <div className="category-selection-container">
      <div className="category-selection-title">
        Select your budget range:
      </div>
      <FormControl sx={{ marginTop: 2, width: 150 }} className="category-select-control">
        <Select
          value={selectedBudget}
          onChange={handleBudgetChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Select Budget' }}
          className="category-select"
        >
          <MenuItem value="" disabled>
          </MenuItem>
          <MenuItem value="<$100" sx={{fontSize: 17}}>Under $100</MenuItem>
          <MenuItem value="$100-250" sx={{fontSize: 17}}>$100 - $250</MenuItem>
          <MenuItem value="$250-500" sx={{fontSize: 17}}>$250 - $500</MenuItem>
          <MenuItem value="$500-750" sx={{fontSize: 17}}>$500 - $750</MenuItem>
          <MenuItem value="$750-1000" sx={{fontSize: 17}}>$750 - $1000</MenuItem>
          <MenuItem value="$1000+" sx={{fontSize: 17}}>$1000+</MenuItem>
          <MenuItem value="Any price" sx={{fontSize: 17}}>Any Price!</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
