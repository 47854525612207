import React from 'react';
import ContainedButtons from './Button.js'
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function QuizCover({ onStartCategorySelection }) {
  const handleStartCategorySelection = () => {
    onStartCategorySelection();
  };

  return (
    <div>
      <CardMedia
        component="img"
        alt="studio-gear"
        image="https://www.audiogearz.com/wp-content/uploads/2018/02/studio-gear-quiz.png"
        className="cover-image"
      />
      <CardContent className="cover-content">
        <Typography gutterBottom variant="h4" component="div" className="roboto-condensed-300" sx={{ color: '#ff6600', fontSize: 36 }}>
          Find the Perfect Studio Gear For You
        </Typography>
        <Typography className="cover-text" variant="body2" color="text.secondary" sx={{ fontSize: 15 }}>
          Which studio gear is right for you? From headphones to microphones to preamps, answer a few questions and get recommendations tailored to your specific requirements and preferences.
        </Typography>
        <ContainedButtons
          text="Take the Quiz!"
          variant="contained"
          onClick={handleStartCategorySelection}
          backgroundColor="#ff6600"
        />
      </CardContent>
    </div>
  );
}
