import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ExperienceSelection({ onExperienceSelect, selectedCategory }) {
  const [experienceLevel, setExperienceLevel] = useState('');

  const handleExperienceChange = (event) => {
    setExperienceLevel(event.target.value);
    onExperienceSelect(event.target.value);
  };

    return (
      <div className="experience-selection-container">
              <div className="experience-selection-title">
        What is your level of experience with {selectedCategory.toLowerCase()}?
      </div>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="experience level"
            name="experience-level"
            value={experienceLevel}
            onChange={handleExperienceChange}
            style={{ flexDirection: 'row' }}
          >   
            <FormControlLabel value="beginner" control={<Radio />} label={<span style={{ fontSize: '16px' }}>Beginner</span>} style={{padding: 10}} />
            <FormControlLabel value="advanced" control={<Radio />} label={<span style={{ fontSize: '16px' }}>Advanced</span>} style={{padding: 10}} />
          </RadioGroup>
        </FormControl>
      </div>
    );

}
