import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress, Box } from '@mui/material';

export default function QuizResults({ handleLoading, selectedCategory, experienceLevel, budget, responses }) {
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [recommendation, setRecommendation] = useState('');
  const [amznObj, setAmznObj] = useState('');

  const formatResponses = (responsesArray) => {
    return responsesArray.map(response => JSON.stringify(response)).join('\n');
  };

  const createJSONObject = () => {
    return {
      selectedCategory: selectedCategory,
      experienceLevel: experienceLevel,
      budget: budget,
      responses: formatResponses(responses)
    };
  };

  const jsonObject = createJSONObject();

  const sendToServer = async () => {
    handleLoading(true);
    try {
      const serverUrl = process.env.NODE_ENV === 'development'
        ? 'http://localhost:3001/recommendations'
        : 'https://shrouded-coast-08564-5f4a35bce45b.herokuapp.com/recommendations';

      const response = await fetch(serverUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonObject)
      });
      const data = await response.json();
      console.log('data', data)
      const splitResponse = data.openAIResponse.split('\n');
      setTitle(splitResponse[0]);
      setRecommendation(splitResponse.slice(2).join('\n'));
      setAmznObj(data.amazonProducts);
    } catch (error) {
      console.error('Error sending data to server:', error);
      handleLoading(false);
      setRecommendation('Failed to get recommendations. Please try again later.');
    } finally {
      handleLoading(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    sendToServer();
  }, []);

  return (
    <div style={{ padding: '54px 32px', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography className="roboto-condensed-300 black-font" variant="h5" sx={{ fontSize: 22 }} gutterBottom>
            Finding your perfect match...
          </Typography>
          <CircularProgress sx={{ marginLeft: 2, marginBottom: 1 }} size={40} thickness={4} />
        </Box>
      ) : (<>
        <Typography className="roboto-condensed-300 black-font" variant="h5" sx={{ fontSize: 22, marginTop: "-25px" }} gutterBottom>
          Your perfect match:
        </Typography>
        <div className="product-title">
          {title}
        </div>

        {amznObj && amznObj.SearchResult && amznObj.SearchResult.Items && amznObj.SearchResult.Items[0] && (
          <div className="paapi5-pa-ad-unit pull-left">
            <div className="paapi5-pa-product-container">
              <div className="paapi5-pa-product-image">
                <div className="paapi5-pa-product-image-wrapper">
                  <a
                    className="paapi5-pa-product-image-link"
                    href={amznObj.SearchResult.Items[0].DetailPageURL}
                    title={amznObj.SearchResult.Items[0].ItemInfo.Title.DisplayValue}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="paapi5-pa-product-image-source"
                      src={amznObj.SearchResult.Items[0].Images.Primary.Large.URL}
                      alt={amznObj.SearchResult.Items[0].ItemInfo.Title.DisplayValue}
                    />
                  </a>
                </div>
              </div>
              <div className="paapi5-pa-product-details">
                <div className="paapi5-pa-product-title">
                  <a
                    className="paap5-pa-product-title-link"
                    href={amznObj.SearchResult.Items[0].DetailPageURL}
                    title={amznObj.SearchResult.Items[0].ItemInfo.Title.DisplayValue}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {amznObj.SearchResult.Items[0].ItemInfo.Title.DisplayValue}
                  </a>
                </div>
                <div className="paapi5-pa-product-list-price">
                  <span className="paapi5-pa-product-list-price-value"></span>
                </div>
                <a href={amznObj.SearchResult.Items[0].DetailPageURL} className="current-price" title={amznObj.SearchResult.Items[0].ItemInfo.Title.DisplayValue}
                  target="_blank" rel="noopener noreferrer">
                  See current price</a>
                <div className="paapi5-pa-product-prime-icon">
                  <span className="icon-prime-all"></span>
                </div>
              </div>
            </div>
          </div>
        )}



        <Typography className="recommendation-text" variant="body2" color="text.secondary" style={{ whiteSpace: 'pre-line', fontSize: 15, margin: '24px 32px' }} gutterBottom>
          {recommendation}
        </Typography>
      </>
      )}
    </div>
  );
}
