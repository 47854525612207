import React, { useState, useRef, useEffect } from 'react';
import quizData from './quiz-content.json'; 
import ContainedButtons from './Button.js';
import LikertQuestion from './LikertQuestion.js';

export default function Questions(props) {
  const { categories } = quizData.quiz;
  const { selectedCategory, experienceLevel } = props; 

  const selectedCategoryData = categories.find(category => category.name === selectedCategory);
  const selectedCategoryQuestions = experienceLevel === 'beginner' 
    ? selectedCategoryData?.questions.beginner || []
    : selectedCategoryData?.questions.advanced || [];

  const [responses, setResponses] = useState(Array(selectedCategoryQuestions.length).fill(null));
  const questionRefs = useRef([]);
  const quizStartRef = useRef();

  useEffect(() => {
    quizStartRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  function handleResponseChange(index, value) {
    const updatedResponses = [...responses];
    updatedResponses[index] = value;
    setResponses(updatedResponses);

    if (index < selectedCategoryQuestions.length - 1) {
      if (index > 0) {
        const scrollOffset = 233;
        const scrollOptions = {
          top: questionRefs.current[index + 1].offsetTop - scrollOffset,
          behavior: 'smooth',
        };
        questionRefs.current[index + 1].offsetParent.scrollTo(scrollOptions);
      }
    }
  }

  function handleSubmit() {
    props.onQuizEnd(responses);
  }

  const allQuestionsAnswered = !responses.some(response => response === null);

  return (
    <div className="question-slim" ref={quizStartRef}>

      {selectedCategoryQuestions.map((question, index) => {
        let questionComponent = (
          <LikertQuestion
            key={index}
            ref={el => questionRefs.current[index] = el}
            className="question"
            question={question}
            onResponseChange={(value) => handleResponseChange(index, value)}
            answered={responses[index] !== null}
          />
        );

        return <div key={index}>{questionComponent}</div>;
      })}
      <ContainedButtons
        text="Submit"
        variant="contained"
        onClick={handleSubmit}
        disabled={!allQuestionsAnswered}
      />
    </div>
  );
}
