import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export default function ContainedButtons(props) {
  const { text, variant, disabled, href, onClick } = props;

  return (
    <Stack sx={{ justifyContent: 'center' }} direction="row" spacing={2}>
      <Button sx={{ backgroundColor: '#08c', margin: 2, fontSize: 12, width: '45%' }} variant={variant} disabled={disabled} href={href} onClick={onClick}>
        {text}
      </Button>
    </Stack>
  );
}
