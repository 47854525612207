import React, { useState } from 'react';
import Card from '@mui/material/Card';
import QuizCover from './QuizCover.js';
import CategorySelection from './CategorySelection.js';
import ExperienceSelection from './ExperienceSelection'
import Questions from './Questions.js';
import BudgetSelection from './BudgetSelection.js';
import QuizResults from './QuizResults.js';
import quizData from './quiz-content.json';
import Button from '@mui/material/Button';

export default function Quiz() {
  const [quizStatus, setQuizStatus] = useState('cover');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedBudget, setSelectedBudget] = useState('');
  const [experienceLevel, setExperienceLevel] = useState('');
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(false);

  const showResetButton = quizStatus !== 'cover' && quizStatus !== 'categorySelection' && loading != true;

  function handleStartQuiz() {
    setQuizStatus('categorySelection');
  }

  function resetQuiz() {
    setSelectedCategory('');
    setSelectedBudget('');
    setExperienceLevel('');
    setResponses([]);
    setQuizStatus('categorySelection');
  }

  function handleCategorySelection(category) {
    setSelectedCategory(category);
    setQuizStatus('experienceSelection');
  }

  function handleExperienceSelection(experience) {
    setExperienceLevel(experience);
    setQuizStatus('questions');
  }

  function handleQuizEnd(responses) {
    setResponses(responses);
    setQuizStatus('budgetSelection');
  }

  function handleLoading(value) {
    setLoading(value);
  }

  function handleBudgetSelection(budget) {
    setSelectedBudget(budget)
    setQuizStatus('results');
  }

  const { categories } = quizData.quiz;

  return (
    <Card className="quiz" sx={{ position: 'relative' }}>

      {quizStatus === 'cover' && (
        <QuizCover onStartCategorySelection={handleStartQuiz} />
      )}
      {quizStatus === 'categorySelection' && (
        <CategorySelection categories={categories} onCategorySelect={handleCategorySelection} onStartQuiz={handleCategorySelection} />
      )}
      {quizStatus === 'questions' && (
        <Questions selectedCategory={selectedCategory} experienceLevel={experienceLevel} onQuizEnd={handleQuizEnd} />
      )}
      {quizStatus === 'budgetSelection' && (
        <BudgetSelection experienceLevel={experienceLevel} onBudgetSelect={handleBudgetSelection} />
      )}
      {quizStatus === 'experienceSelection' && (
        <ExperienceSelection onExperienceSelect={handleExperienceSelection} selectedCategory={selectedCategory} />
      )}
      {quizStatus === 'results' && (
        <QuizResults handleLoading={handleLoading} selectedCategory={selectedCategory} experienceLevel={experienceLevel} budget={selectedBudget} responses={responses} />
      )}

      {showResetButton && (
        <Button
          variant="contained"
          onClick={resetQuiz}
          sx={{
            position: 'absolute', fontSize: 10, bottom: 20, right: 30, zIndex: 10, backgroundColor: 'white', color: 'black',
            '&:hover': {
              color: 'white',
            }
          }}
        >
          Start Over
        </Button>
      )}
    </Card>
  );
}
